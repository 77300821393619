import { createAsyncThunk } from "@reduxjs/toolkit";
import { ViewedInfoRequest } from "../interfaces";
import { getMeetingViewedInfoById, saveCourseMeetingViewInfo } from "../../../../../../api";

export const getMeetingViewedInfo = createAsyncThunk(
  "course-meeting/getMeetingViewedInfo",
  async (id: number) => (await getMeetingViewedInfoById(id)) as any
);

export const postMeetingViewedInfo = createAsyncThunk(
  "course-meeting/postMeetingViewedInfo",
  async (info: ViewedInfoRequest & { meetingId: number }) => {
    const { meetingId, ...request } = info;
    return saveCourseMeetingViewInfo({ id: meetingId, videos: request.videos || "" });
  }
);
