import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Capacitor } from "@capacitor/core";
import {
  auth,
  authAnonymous,
  authRegisterExists,
  checkEmail,
  codeEmailCheckup,
  getCapabilities,
  getPasswordPolicy,
  getRegistrationIsAllowed,
  getSharedCourseById,
  logout,
  policyCheck,
  registerVerify,
  sendCodeToEmail,
  userRegister,
} from "../../api";
import { NewUser } from "../../types/interfaces";

const getToken = createAsyncThunk(
  "auth/getToken",
  async (payload: { login: string; password: string }, thunkAPI) => {
    try {
      const request = await auth({
        login: payload.login.trim(),
        password: payload.password,
        mobile_application: Capacitor.isNativePlatform(),
      });

      return {
        idToken: request.headers.get("Authorization"),
        refreshToken: request.headers.get("Refresh"),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const getCapabilitiesThunkAction = createAsyncThunk("auth/getCapabilities", async (_, thunkAPI) => {
  try {
    const request = await getCapabilities();
    const response = await request.json();
    return {
      //@ts-ignore
      data: response?.data || [],
      code: request.status,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
    });
  }
});

const emailCheckup = createAsyncThunk(
  "auth/emailCheckup",
  async (payload: { email: string }, thunkAPI) => {
    try {
      const request = await codeEmailCheckup(payload);
      const response = await request.json();

      return {
        email: payload.email,
        status: 200,
        data: response,
      };
    } catch (error: any) {
      const parsedJson = await error.response.json();
      return thunkAPI.rejectWithValue({
        email: payload.email,
        status: error.response.status,
        data: parsedJson,
      });
    }
  }
);

const getTokenAnonymous = createAsyncThunk(
  "auth/getTokenAnonymous",
  async (payload: string, thunkAPI) => {
    try {
      const request = await authAnonymous({
        course_share_id: payload,
      });

      const response = (await request.json()) as any;

      return {
        status: request.status,
        accessToken: response.data["Access-Token"],
        idToken: response.data["Authorization"],
        refreshToken: response.data["Refresh"],
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const getSharedCourseId = createAsyncThunk(
  "auth/getSharedCourseId",
  async (payload: string, thunkAPI) => {
    try {
      const request = await getSharedCourseById(+payload);
      const response = await request.json();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const registerVerifyThunkAction = createAsyncThunk(
  "auth/registerVerify",
  async (payload: { email: string; code: string }, thunkAPI) => {
    try {
      const request = await registerVerify(payload);
      const response = await request.json();

      return {
        status: request.status,
        data: response,
      };
    } catch (error: any) {
      const parsedJson = await error.response.json();
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        data: parsedJson,
      });
    }
  }
);

const getCode = createAsyncThunk("auth/getCode", async (payload: { email: string }, thunkAPI) => {
  try {
    const request = await sendCodeToEmail(payload.email);
    const response = await request.json();

    return {
      email: payload.email,
      status: 200,
      data: response,
    };
  } catch (error: any) {
    const parsedJson = await error.response.json();
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      data: parsedJson,
    });
  }
});

const mailCheck = createAsyncThunk(
  "auth/mailCheck",
  async (payload: { email: string }, thunkAPI) => {
    try {
      const request = await checkEmail(payload.email);
      const response = await request.json();

      return {
        email: payload.email,
        status: 200,
        //@ts-ignore
        is_allowed: response?.data?.is_allowed,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        email: payload.email,
        status: error.response.status,
      });
    }
  }
);

const getRegistrationIsAllowedThunkAction = createAsyncThunk(
  "auth/registrationIsAllowed",
  async (_arg, thunkAPI) => {
    try {
      const request = await getRegistrationIsAllowed();
      const response = await request.json();

      return {
        status: request.status,
        //@ts-ignore
        allowed: response.data?.allow,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
      });
    }
  }
);

const isExist = createAsyncThunk("auth/isExist", async (payload: { email: string }, thunkAPI) => {
  try {
    const request = await authRegisterExists(payload.email);
    const response = await request.json();

    return {
      status: request.status,
      //@ts-ignore
      code: response.code,
    };
  } catch (error: any) {
    const parsedJson = await error.response.json();
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      ...parsedJson,
    });
  }
});

const userRegisterThunkAction = createAsyncThunk(
  "auth/userRegister",
  async (payload: NewUser, thunkAPI) => {
    try {
      const request = await userRegister(payload);

      return {
        status: request.status,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
      });
    }
  }
);

const getPasswordPolicyThunkAction = createAsyncThunk(
  "auth/getPasswordPolicy",
  async (payload, thunkAPI) => {
    try {
      return await getPasswordPolicy().json();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.status,
      });
    }
  }
);

const codeSend = createAsyncThunk("auth/codeSend", async (payload: { email: string }, thunkAPI) => {
  try {
    const request = await sendCodeToEmail(payload.email);

    if (request.status === 200) {
      return {
        status: 200,
        email: payload.email,
      };
    }
  } catch (error: any) {
    const parsedJson = await error.response.json();
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      data: parsedJson,
    });
  }
});

const policyCheckThunkAction = createAsyncThunk(
  "auth/policyCheck",
  async (payload: { email: string }, thunkAPI) => {
    try {
      const request = await policyCheck(payload.email);
      const response = await request.json();
      if (request.status === 200) {
        //@ts-ignore
        return response.data;
      }
    } catch (error: any) {
      const parsedJson = await error.response.json();
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        data: parsedJson,
      });
    }
  }
);

const logoutResponse = createAsyncThunk("auth/logoutResponse", async (payload, thunkAPI) => {
  try {
    await logout();
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
    });
  }
});

const updateLocalStorage = (oidc: string) => {
  localStorage.oidc = JSON.stringify(oidc);
};

const authInitalState = {
  recovery: {
    error: {
      isError: false,
      code: "",
    },
  },
  registration: {
    avatar: 0,
    requirements: {
      password: {},
    },
    verifyError: {},
  },
  oidc: (function () {
    if (localStorage.oidc) {
      return JSON.parse(localStorage.oidc);
    }
    return {};
  })(),
  authType: "email",
  authLastError: null,
  win: {
    showcase: {
      expanded: false,
    },
  },
  userExist: false,
  emailCheck: {
    statusCode: "",
    interval: "",
    codeCreate: "",
  },
  registrationIsAllowed: false,
  authRequestIsLoading: false,
  capabilities: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitalState,
  reducers: {
    init(state, action) {
      state.oidc = localStorage?.oidc ? JSON.parse(localStorage.oidc) : {};
    },
    authTypeToggle(state, action) {
      if (state.authType === "login") {
        state.authType = "email";
      } else {
        state.authType = "login";
      }
    },
    clearAuthError(state) {
      state.authLastError = null;
    },
    setAuthTypeLogin(state, action) {
      state.authType = "login";
    },
    setAuthTypeEmail(state, action) {
      state.authType = "email";
    },
    changeShowcaseExpand(state, action) {
      state.win.showcase.expanded = action.payload;
    },
    clear(state) {
      state.oidc = {};
      state.userExist = false;
      // @ts-ignore
      updateLocalStorage({});
    },
    setRegistrationUserAvatar(state, action) {
      state.registration.avatar = parseInt(action.payload);
    },
    setTokens(state, action) {
      state.oidc = action.payload;
      state.authLastError = null;
      // @ts-ignore
      updateLocalStorage(state.oidc);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getToken.fulfilled, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc = action.payload;
        state.authLastError = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getToken.pending, (state, action) => {
        state.authRequestIsLoading = true;
      })
      .addCase(getToken.rejected, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc = {};
        // @ts-ignore
        state.authLastError = action?.payload?.status ?? "unknown_error";
        // @ts-ignore
        updateLocalStorage({});
      })
      .addCase(getTokenAnonymous.fulfilled, (state, action) => {
        state.oidc = Object.assign(state.oidc, action.payload);
        state.oidc.shared = true;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getTokenAnonymous.rejected, (state, action) => {
        state.oidc = {};
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getSharedCourseId.fulfilled, (state, action) => {
        //@ts-ignore
        state.oidc.course_id = action.payload?.course_id;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getSharedCourseId.rejected, (state, action) => {
        state.oidc.courseId = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(getCapabilitiesThunkAction.fulfilled, (state, action) => {
        state.capabilities = action.payload.data;
      })
      .addCase(isExist.fulfilled, (state) => {
        state.authRequestIsLoading = false;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(isExist.rejected, (state) => {
        state.authRequestIsLoading = false;
        state.oidc.courseId = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(isExist.pending, (state) => {
        state.authRequestIsLoading = true;
        state.oidc.courseId = null;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(registerVerifyThunkAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.registration.requirements = action.payload.data?.requirements;
        state.recovery.error.isError = true;
        state.registration.verifyError = {};
      })
      .addCase(registerVerifyThunkAction.rejected, (state, action) => {
        // @ts-ignore
        state.registration.verifyError = action.payload;
        state.recovery.error.isError = false;
        // @ts-ignore
        state.recovery.error.code = action.payload?.data?.code;
        state.registration.requirements = { password: {} };
      })
      .addCase(mailCheck.fulfilled, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc.email = action.payload.email;
      })
      .addCase(mailCheck.pending, (state) => {
        state.authRequestIsLoading = true;
      })
      .addCase(mailCheck.rejected, (state) => {
        state.authRequestIsLoading = false;
        state.recovery.error.isError = true;
        state.registration.verifyError = {};
      })
      .addCase(codeSend.fulfilled, (state, action) => {
        state.authRequestIsLoading = false;
        state.oidc.email = action.payload?.email;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(codeSend.pending, (state) => {
        state.authRequestIsLoading = true;
      })
      .addCase(codeSend.rejected, (state) => {
        state.authRequestIsLoading = false;
      })
      .addCase(emailCheckup.fulfilled, (state, action) => {
        state.oidc.email = action.payload?.email;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(policyCheckThunkAction.fulfilled, (state, action) => {
        state.oidc.policyCheck = action.payload?.showpolicy;
        // @ts-ignore
        updateLocalStorage(state.oidc);
      })
      .addCase(emailCheckup.rejected, (state, action) => {
        // @ts-ignore
        state.temporaryCodeError = action?.payload?.status ?? null;
      })
      .addCase(getPasswordPolicyThunkAction.fulfilled, (state, action) => {
        // @ts-ignore
        state.registration.requirements = { password: action?.payload.data || {} };
      })
      .addCase(getRegistrationIsAllowedThunkAction.rejected, (state, action) => {
        state.registrationIsAllowed = false;
      })
      .addCase(getRegistrationIsAllowedThunkAction.fulfilled, (state, action) => {
        state.registrationIsAllowed = action?.payload?.allowed;
      });
  },
});

export const {
  init,
  changeShowcaseExpand,
  clear,
  setTokens,
  setRegistrationUserAvatar,
  authTypeToggle,
  clearAuthError,
} = authSlice.actions;
export {
  getToken,
  getTokenAnonymous,
  getSharedCourseId,
  userRegisterThunkAction,
  registerVerifyThunkAction,
  getCode,
  isExist,
  emailCheckup,
  mailCheck,
  policyCheckThunkAction,
  codeSend,
  getPasswordPolicyThunkAction,
  getRegistrationIsAllowedThunkAction,
  logoutResponse,
  getCapabilitiesThunkAction,
};
export default authSlice.reducer;
