import { EndPoints } from "../../app/endpoints";
import { KY } from "../ky-instance";

export const getMulticourseContentSections = ({
  id,
  signal,
}: {
  id: number;
  signal?: AbortSignal;
}) => KY.get(EndPoints.MULTICOURSE_PAGE_CONTENT_SECTIONS(id), { signal }).json();
