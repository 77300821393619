import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveMeetingViewed } from "../../../../../../api";

export const postMeetingViewed = createAsyncThunk(
  "course-meeting/postMeetingViewed",
  async (meetingId: number) => {
    await saveMeetingViewed(meetingId);
    return meetingId;
  }
);
